<template>
  <a-menu @select="changeCurrentMenu" mode="horizontal">
    <!-- <a-menu-item :disabled="stillExam(false)" key="Dashboard Student" class="subMenuDetail">
      <router-link :disabled="stillExam(false)" :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'"
        :to="{ name: 'Dashboard Student' }">
        <a-icon type="home" />Dashboard
      </router-link>
    </a-menu-item>
    <a-menu-item :disabled="stillExam(false)" key="Schedule Student" class="subMenuDetail" id="menu-schedule-student">
      <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'"
        :to="{ name: 'Schedule Student' }">
        <a-icon type="calendar" />Schedule
      </router-link>
    </a-menu-item>
    <a-menu-item :disabled="stillExam(false)" key="Class Student" class="subMenuDetail">
      <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'" :to="{ name: 'Class Student' }">
        <a-icon type="appstore" />Class
      </router-link>
    </a-menu-item> -->
    <!-- <a-menu-item
      :disabled="stillExam(true)"
      key="Cbt Student"
      class="subMenuDetail"
    >
      <router-link
        :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'"
        :to="{ name: 'Cbt Student' }"
      >
        <a-icon type="file-protect" />CBT
      </router-link>
    </a-menu-item> -->
    <!-- <a-menu-item :disabled="stillExam(false)" key="Report Student" class="subMenuDetail">
      <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'" :to="{ name: 'Report Student' }">
        <a-icon type="book" />Report
      </router-link>
    </a-menu-item>
    <a-menu-item :disabled="stillExam(true)" key="Ppdb Student" class="subMenuDetail" v-if="ppdb">
      <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'" :to="{ name: 'PPDB Student' }">
        <a-icon type="file-text" />PPDB
      </router-link>
    </a-menu-item> -->
  </a-menu>
</template>
<script>
export default {
  data() {
    return {
      ppdb: false,
    }
  },
  methods: {
    changeCurrentMenu(payload) {
      if (this.ppdbIsFulfilled) {
        this.$store.commit('menu/SET_STATE', {
          currentMenu: [payload.key],
        })
      }
    },
    stillExam(mes) {
      if (localStorage.isPpdb) {
        return !mes
      } else {
        if (
          (this.isOnExam || (this.isOnExamState && !this.isOnExam)) &&
          this.$route.name === 'Cbt Start Exam Student'
        ) {
          // console.log('masuk exam')
          return true
        } else {
          // console.log('enggak masuk exam')
          return false
        }
      }
    },
  },
  created() {
    if (localStorage.isPpdb) {
      this.ppdb = true
    }
    if (
      this.$router.history.current.name === 'Detail Schedule Student' &&
      this.isLive
    ) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Schedule Student'],
      })
    } else {
      if (this.$router.history.current.name.split(' ').includes('Schedule')) {
        this.$store.commit('menu/SET_STATE', {
          currentMenu: ['Schedule Student'],
        })
      } else if (
        this.$router.history.current.name.split(' ').includes('Subject')
      ) {
        this.$store.commit('menu/SET_STATE', {
          currentMenu: ['Class Student'],
        })
      } else if (this.$router.history.current.name.split(' ').includes('Cbt')) {
        this.$store.commit('menu/SET_STATE', {
          currentMenu: ['Cbt Student'],
        })
        // } else if (this.$router.history.current.name.split(' ').includes('Report')) {
        //   this.$store.commit('menu/SET_STATE', {
        //     currentMenu: ['Report Student'],
        //   })
      } else {
        this.$store.commit('menu/SET_STATE', {
          currentMenu: [this.$router.history.current.name],
        })
      }
    }
  },
  computed: {
    currentMenu: {
      get: function () {
        return this.$store.state.menu.currentMenu
      },
      set: function (value) {
        if (this.isLive) {
          this.$store.commit('menu/SET_STATE', {
            currentMenu: ['Schedule Student'],
          })
        } else if (this.isOnExam) {
          this.$notification.error({
            message: 'On Exam',
            description: 'Sorry. You are on exam right now.',
          })
        } else {
          this.$store.commit('menu/SET_STATE', {
            currentMenu: value,
          })
        }
      },
    },
    isLive() {
      return this.$store.state.live.isLive
    },
    currentRouteIsDetailSchedule() {
      return this.$route.name.match(/Detail Schedule/gi) !== null
    },
    isOnExam() {
      return this.$store.state.cbt.isOnExam
    },
    isOnExamState() {
      return JSON.parse(localStorage.isOnExam)
    },
    // stillExam() {
    //   if ((this.isOnExam || (this.isOnExamState && !this.isOnExam)) && this.$route.name === 'Cbt Start Exam Student') {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    ppdbIsFulfilled() {
      return this.$store.state.ppdb.ppdbIsFulfilled
    },
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
